/* .shop {
  position: relative;
  margin-top: 10px;
  background: white;
  transition: all 0.3s ease-in-out;
  margin: 4px;
}

.shop:hover {
  transform: scale(1.04);
}

.shop1 {
  border: 1px solid #00000026;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px;
  height: 300px;
  width: 200px;
}

.shop-content {
  padding: 10px 15px 22px;
  text-align: center;
}

.price {
  display: block;
}

.sc-price {
  margin-top: 2px;
}

.sc-price span.new-price {
  color: #1d74fb;
  font-weight: 700;
  font-size: 18px;
  margin-right: 7px;
}

.sc-price span.old-price {
  font-size: 18px;
  text-decoration: line-through;
  color: #777;
  padding-right: 12px;
}

.shop-content a {
  color: #787777;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
}

.shop-content h3 {
  color: #000000d4;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 3px;
  height: 55px;
  overflow: hidden;
}

i.fill {
  color: #ffc107;
  font-size: 12px;
}

i.eci-star {
  color: #8c8c8c;
  font-size: 12px;
}

.image-link:hover h3 {
  color: #007185;
  text-decoration: underline;
}

.pb-5 {
  padding-bottom: 3rem !important;
  padding: 0px 100px !important;
}

.carousel-box {
  margin-top: 50px;
}

@media(max-width: 430px) {
  .pb-5 {
    padding: 0px 20px !important;
  }
} */


body {
  font-family: "Epilogue", sans-serif !important;
}

.trending-grid-box {
  margin-top: 50px;
}

.trending-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  gap: 20px;
}

.shop2 {
  border: 1px solid #00000026;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px;
  /* padding: 10px; */
  background-color: #fff;
  text-align: center;
}

.shop-content {
  padding: 10px 5px 15px;
  text-align: start;
}

.shop-content h3 {
  color: #000000d4;
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 3px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 96%;
  white-space: nowrap;
}

.price {
  display: block;
}

.sc-price {
  margin-top: 2px;
}

.sc-price span.new-price {
  color: #1d74fb;
  font-weight: 700;
  font-size: 18px;
  margin-right: 7px;
}

.sc-price span.old-price {
  font-size: 18px;
  text-decoration: line-through;
  color: #777;
  padding-right: 12px;
}

.image-link:hover h3 {
  color: #007185;
  text-decoration: underline;
}

.trending-carousel-box {
  padding-bottom: 1rem !important;
  padding: 0px 100px !important;
}

@media(max-width: 430px) {
  .trending-carousel-box {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-bottom: 1rem !important;
  }
}

/* .pb-5 {
  padding-bottom: 3rem !important;
  padding: 0px 100px !important;
}

@media(max-width: 430px) {
  .pb-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
} */

.trending-carousel-box {
  margin-top: 50px;
}

.slick-track {
  display: flex !important;
}

.slick-list {
  overflow: hidden;
}

.slick-slide {
  padding: 0px;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.slick-prev,
.slick-next {
  z-index: 2;
  top: 50%;
  width: 40px;
  height: 40px;
}



/* Desktop/Tablet View */
.trending-desktop-carousel .shop2 {
  border: 1px solid #00000026;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px;
  padding: 0px 0px;
  background-color: #fff;
  text-align: center;
}

.trending-desktop-carousel .shop-content {
  padding: 10px 5px 22px;
  text-align: start;
}

/* Mobile View */
.mobile-carousel .mobile-shop2 {
  border: none;
  box-shadow: none;
  padding: 0;
  background-color: transparent;
  text-align: center;
}

.mobile-carousel .mobile-shop-content h3 {
  color: #000000d4;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 3px;
}

.mobile-carousel img {
  height: 120px;
}

.mobile-carousel .mobile-slide {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mobile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 2 items per row */
  gap: 10px;
  padding: 10px;
}

.mobile-item {
  background-color: white;
  padding: 10px;
  border: 1px solid #00000026;
  border-radius: 10px;
  text-align: center;
}

.mobile-shop-content h3 {
  color: #000000d4;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 3px;
}

/* Hide carousel based on screen size */
@media (max-width: 768px) {
  .trending-desktop-carousel {
    display: none;
  }

  .slick-prev {
    left: 0px !important;
  }

  .slick-next {
    right: 0px !important;
  }
}

@media (min-width: 769px) {
  .mobile-carousel {
    display: none;
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }
}

.slick-slide .slick-active .slick-current {
  background: #fff !important;
}

.slick-next:before {
  display: none !important;
}

.slick-prev:before {
  display: none !important;
}



.qty-buttons {
  display: flex;
  align-items: center;
}

.qty-buttons button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #ff7043;
}

.qty-buttons button:hover {
  border-radius: 0;
}

@media only screen and (max-width:767px) {
  .qty-buttons {
    display: contents;
  }

  .qty-buttons button {
    padding: 0px 4px;
  }
}

.image-containers {
  position: relative;
}

.ribbon {
  position: absolute;
  /* top: 10px; */
  /* left: 10px; */
  background-color: red;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  /* border-radius: 4px; */
  z-index: 1;
}

.heart-icon {
  position: absolute;
  top: 0px;
  right: 5px;
  /* color: #ff7043; */
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
  transition: transform 0.2s ease-in-out;
}

.qty-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 5px; */
}

.qty-button {
  background-color: white;
  color: #ff7043;
  border: none;
  /* border-radius: 4px; */
  width: 25px;
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid;
}

.qty-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]:focus {
  /* border: none !important; */
  outline: 1px solid #ff7043;
  outline-offset: -2px;
}