body {
    font-family: "Epilogue", sans-serif !important;
}

.terms-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.terms-content {
    max-width: 900px;
    margin: 0 auto;
}

.terms-title {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin-bottom: 30px;
}

.terms-clauses {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.terms-clause {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.terms-clause h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000;
}

.terms-clause p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
}
