body {
    font-family: "Epilogue", sans-serif !important;
}

.myorder-wrap {
    margin: 20px 0;
}

.myorder-coent {
    border-radius: 8px;
    box-shadow: 0px 0px 20px #5fb2f336;
    background: white;
    margin: 18px 0;
}

.myorder-box {
    padding: 16px;
    display: flex;
    align-items: center;
}

.myorder-flex {
    display: flex;
    justify-content: space-between;
}

.order-check {
    /* color: #298f44; */
    color: rgb(55, 94, 151);
    background: #edf6ff;
    width: 60px;
    height: 60px;
    border-radius: 14px;
    padding: 16px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.order-loc {
    /* font-size: 22px; */
    font-size: 18px;
    font-weight: 700;
}

.or-border {
    border-bottom: 1px solid #e3e1e175;
}

.orl-border {
    border-left: 1px solid #e3e1e175;
}

.orr-border {
    border-right: 1px solid #e3e1e175;
}

.myorder-btn {
    color: rgb(55, 94, 151) !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    margin-top: 0px !important;
}

.myorder-product {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.moorder-pro {
    width: 66px;
    height: 70px;
    border-radius: 11px;
    border: 1px solid #d5d4d947;
    text-align: center;
    background: #f3f2f8;
}

.moorder-pro img {
    width: 66px;
    height: 70px;
    border-radius: 11px;
}

.myorder-more {
    font-weight: 700;
    font-size: 22px;
    margin: 14px 0;
    display: block;
    color: #a8abb2;
}

.myorderbtn {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    padding-left: 20px !important;
    justify-content: center;
}



@media (min-width: 768px) {
    .delivery-type {
        /* margin-left: 2%; */
        width: 16%;
    }

    .delivery-address {
        /* margin-left: 2%; */
        width: 50%;
    }

    .myorder-header {
        width: 85%;
    }

    .myorder-orderid {
        width: 30%;
    }
}


/* .delivery-type,
.delivery-address {
    font-size: 16px;
    font-weight: 500;
} */

@media (max-width: 767px) {

    .delivery-type,
    .delivery-address {
        display: none;
    }
}