html {
  /* font-size: 65.2%; */
  /* font-family: Montserrat, sans-serif; */
}

body {
  font-family: "Epilogue", sans-serif !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.e_login_content {
  width: 400px;
  padding: 2em 2.2em;
  text-align: center;
}

.e_head {
  font-weight: 700;
  font-size: 20px;
  text-align: left;
}

span.loginwith {
  display: block;
  width: 100%;
  margin-top: 1em;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* span {
    color: #333;
    font-size: 1.4em;
    display: inline-block;
    margin: 15px auto;
    font-weight: 100;
  } */

.login svg {
  margin: 1em;
  stroke: #999;
}

.e_form input {
  background: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  font-size: 1.4em;
}

span.remember {
  float: left;
}

span.remember::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 2px solid #999;
  vertical-align: top;
  margin-right: 4px;
}


span.clearfix {
  clear: both;
  display: table;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-google-login,
.FacebookLoginButton {
  margin: 10px 0;
}




.login .e_visited {
  border-radius: 0px;
  width: 100%;
  border: none;
  background: linear-gradient(45deg, #21a9ff, #0380cf);
  display: block;
  margin: 1em auto;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  padding: 0.8em 2em;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.e_visited:hover {
  cursor: pointer;
}

.e_visited:active {
  transform: scale(0.95);
}

span.loginwith {
  display: block;
  width: 100%;
  margin-top: 1em;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.loginwith::before {
  content: "";
  display: inline-block;
  width: 42%;
  height: 1px;
  background: #aaa;
  vertical-align: middle;
  margin-right: 5%;
}

span.loginwith::after {
  content: "";
  display: inline-block;
  width: 45%;
  height: 1px;
  background: #aaa;
  vertical-align: middle;
  margin-left: 5%;
}

.e_register p {
  font-size: 13px;
}

.e_register p a {
  font-size: 13px;
  color: #4f73f7 !important;
  transition: 0.3s
}

.e_register p span {
  margin: 0;
}

.e_register p a:hover {
  font-size: 13px;
  color: #3861f5 !important;
  text-decoration: none;
}

/* body {
    background: #e9ebee;
    color: #1d2129;
  } */

.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: radial-gradient(circle, #89f7fe 0%, #66a6ff 100%); */
  /* background: linear-gradient(135deg, #2193b0 0%, #6dd5ed 100%); */
  /* background: linear-gradient(135deg, #ee7c2c, #6383fa, #44c767); */
  background: linear-gradient(135deg, #3f87a6, #ebf8e1, #f69d3c);
  /* background-image: linear-gradient(0.25turn, #ee7c2c, #ebf8e1, #6383fa); */
  /* background-color: #e9ebee; */
  background-size: 400% 400%;
  animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.e_login {
  width: 400px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 14px 28px -10px rgba(0, 0, 0, 0.1), 0 10px 10px -10px rgba(0, 0, 0, 0.02);
  margin: 0px auto;
}

.login {
  position: relative;
  background: white;
  border-radius: 20px;
}

@media screen and (max-width:768px) {
  .e_login {
    padding: 0;
  }

}

.forget {
  text-align: right;
  color: #0380cf;
  font-size: 12px;
  padding: 5px 0px 18px;
}

.social-login {
  display: contents;
  justify-content: center;
  margin-top: 20px;
}

.social-btn {
  /* border: none; */
  /* border-radius: 30px; */
  padding: 10px 20px;
  /* margin: 0 5px; */
  font-size: 1em;
  cursor: pointer;
  /* width: 48%; */
}

.google-btn {
  background: #fff;
  color: #000;
  border: 1px solid #000;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
}

.facebook-btn {
  background-color: #3b5998;
  border: 1px solid #3b5998;
  color: #fff;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}