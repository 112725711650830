body {
  font-family: "Epilogue", sans-serif !important;
}

.breadcrumb-area {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f3f2ee;
  position: relative;
}

.breadcrumb-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.breadcrumb-area .ratio {
  display: block;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}

.breadcrumb-area img {
  display: block;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}

.breadcrumb-title {
  text-align: center;
  margin-top: 5px;
  position: relative;
}

.breadcrumb-title h1 {
  font-weight: 700;
}

.ctg_grid {
  flex-basis: 25%;
  max-width: 25%;
}

.ctg-formation {
  flex-grow: 0;
  flex-shrink: 0;
}

.ctg_grid-area {

  align-items: stretch;
  border: 0 solid black;
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
}

.ctg_grid-akr {
  text-decoration: none;
  background-color: #fff;
  align-items: stretch;
  border-width: 1px;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  position: relative;
  z-index: 0;
  border-radius: 4px;
}

.ctg_grid-title h2 {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
  /* display: -webkit-box; */
  height: 32px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ctg_grid-cont {
  margin: 8px;
  align-items: stretch;
  background-color: rgb(255 255 255);
  ;
  /* border: 0 solid black; */
  box-sizing: border-box;
  display: flex;
  height: 260px;
  width: 188px;
  position: relative;
  border-radius: 20px;
}

@media screen and (min-width: 0px) {
  .ctg_grid-akr {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .ctg_grid {
    flex-basis: 50%;
    max-width: 50%;
  }

  .ctg_grid-cont {
    margin: 3px 3px;
    /* width: 170px; */
    width: auto;
    height: 250px;
  }

  .ctg_grif-content {
    width: 100% !important;
  }

  .ctg_grid-title h2 {
    width: 100% !important;
    padding: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .ctg_grid {
    flex-basis: 25%;
    max-width: 25%;
  }

  .ctg_grid-flx {
    flex-flow: wrap;
    flex: 1 1 0%;
  }

  .ctg_grid-cont {
    margin: 10px;
  }

  .grid_subtitlee {
    font-size: 15px;
    font-weight: 700;
    color: green;
  }
}

.ctg_grif-content {
  padding: 18px;
  width: 85%;
}

.ctg-price-name {
  color: black;
  font-size: 18px;
  font-weight: 500;
}

.ctg-price-symbol {
  color: black;
  top: -10px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding-right: 2px;
}

.ctg-price-whole {
  font-weight: 600;
  color: black;
  font-size: 24px;
}

/*---------*/

.e-desk-left li input {
  margin-right: 10px;
}

.e-desk-left ul {
  list-style: none;
  cursor: pointer;
  padding-left: 0;
}

.e-desk-left li {
  cursor: pointer;
  /* line-height: 26px; */
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}

.e-desk-lst,
a:link,
a:visited {
  text-decoration: none;
  color: #000;
  cursor: pointer;
  /* width: -webkit-fill-available; */
}

.e-desk-left label,
input {
  cursor: pointer;
  color: #6d6c6c;
}

/*--------*/

#filter_show {
  font-size: 18px;
  /* font-weight: 700; */
  text-transform: capitalize;
  /* width: 175px; */
  height: 36px;
  color: #000;
  cursor: pointer;
  user-select: none;
  padding-right: 11px;
}

.filter_sidebar {
  position: fixed;
  top: 0;
  left: -35%;
  /*text-align: center;*/
  width: 100%;
  max-width: 20rem;
  min-height: 100vh;
  background: white;
  box-shadow: 0 0 8px rgba(2, 4, 3, 0.5);
  transition: 0.5s ease;
  z-index: 100;
  padding: 30px;
}

.filter_sidebar.active {
  left: 0;
}

.filter_sidebar .title {
  margin-top: 8%;
}

.filter_sidebar .filter_menu-bar {
  margin: 4% auto;
}

@media (max-width: 992px) {
  .filter_sidebar {
    width: 0;
    display: none;
  }

  .filter_sidebar.active {
    width: 100%;
    max-width: 20rem;
    display: block;
  }
}

.filter_menu-bar .bar {
  position: relative;
  font-size: 14px;
  text-transform: capitalize;
  width: calc(100% - 30%);
  margin: 5% auto;
  padding: 6px 0;
  color: black;
  background: white;
  border-radius: 15px;
}

.bar:hover {
  background: rgb(0, 128, 0, 0.5);
}

.bar .icon {
  position: absolute;
  left: 8%;
}

.filter_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(2, 4, 3, 0.5);
  z-index: 1;
}

.filter_overlay.active {
  display: block;
}

.filter_close {
  display: none;
  background: transparent;
  position: fixed;
  top: 3%;
  right: 3%;
  color: white;
  font-size: 25px;
  cursor: pointer;
  z-index: 9;
  user-select: none;
}

.filter_close.active {
  display: block;
}

.filter_close-btn {
  position: absolute;
  right: 12px;
  top: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 40px;
  cursor: pointer;
  opacity: 0.5;
}

.filter_close-btn:hover {
  opacity: 1;
}

.filter_close-btn:before,
.filter_close-btn:after {
  position: absolute;
  left: 7px;
  content: ' ';
  height: 10px;
  width: 2px;
  background-color: #333;
  top: 3px;
}

.filter_close-btn:before {
  transform: rotate(45deg);
}

.filter_close-btn:after {
  transform: rotate(-45deg);
}

.main-content-wrap {
  padding: 40px 80px;
}

.main-content-wrap .pro-grli-wrapper.left-side-wrap {
  flex-direction: row-reverse;
}

.main-content-wrap .pro-grli-wrapper.left-side-wrap,
.main-content-wrap .pro-grli-wrapper.right-side-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  /*margin: -30px -30px 0;*/
}

@media (max-width: 1499px) {

  .main-content-wrap .pro-grli-wrapper.left-side-wrap,
  .main-content-wrap .pro-grli-wrapper.right-side-wrap {
    /*margin: -30px -15px 0;*/
  }
}

.main-content-wrap .pro-grli-wrapper.left-side-wrap .pro-grli-wrap.product-grid,
.main-content-wrap .pro-grli-wrapper.right-side-wrap .pro-grli-wrap.product-grid {
  width: 80%;
}

.main-content-wrap .pro-grli-wrapper.left-side-wrap .pro-grli-wrap.product-sidebar,
.main-content-wrap .pro-grli-wrapper.right-side-wrap .pro-grli-wrap.product-sidebar {
  width: 20%;
  padding: 10px 20px;
  /* box-shadow: 0 0 10px #375E97;
  border-radius: 20px; */
}

.main-content-wrap .pro-grli-wrapper.left-side-wrap .pro-grli-wrap,
.main-content-wrap .pro-grli-wrapper.right-side-wrap .pro-grli-wrap {
  margin-top: 30px;
  padding: 0 30px;
}

@media (max-width: 1499px) {

  .main-content-wrap .pro-grli-wrapper.left-side-wrap .pro-grli-wrap,
  .main-content-wrap .pro-grli-wrapper.right-side-wrap .pro-grli-wrap {
    padding: 0 15px;
  }
}

.pro-grli-title {
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 1199px) {

  .main-content-wrap .pro-grli-wrapper.left-side-wrap .pro-grli-wrap.product-grid,
  .main-content-wrap .pro-grli-wrapper.right-side-wrap .pro-grli-wrap.product-grid {
    width: 100%;
    padding: 0;
  }

  .main-content-wrap .pro-grli-wrapper.left-side-wrap .pro-grli-wrap.product-sidebar,
  .main-content-wrap .pro-grli-wrapper.right-side-wrap .pro-grli-wrap.product-sidebar {
    display: none;
  }
}

@media (max-width: 768px) {
  .product-sidebar {
    display: none;
  }

  .main-content-wrap {
    padding: 10px 20px;
    height: 100vh;
  }
}

@media (min-width: 1199px) {
  #filter_show {
    display: none;
  }
}

.shop-top-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  margin-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid var
}

.e-price-name {
  font-size: 18px;
}

.ctg_grid-akr:hover .product-name {
  color: #007BFF;
  text-decoration: underline;
}

/* ===========Ribbon============== */

.sold-out-ribbon {
  position: absolute;
  /* top: 60px; */
  right: 0;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  z-index: 5;
  /* transform: rotate(-45deg); */
  transform-origin: 0 0;
  text-align: center;
  width: 85px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 20px;
}

@media (max-width: 768px) {
  .sold-out-ribbon {
    font-size: 12px;
    padding: 4px 10px;
    width: 84px;
  }
}

.price-range-display {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #333;
}

.price-range {
  border: 1px solid gray;
  min-width: 30px;
  text-align: center;
}

input[type=checkbox] {
  accent-color: #007BFF;
  /* accent-color: #375E97; */
}



/* ProductCard.css */
.categories-product-card {
  display: flex;
  align-items: center;
  /* padding: 20px; */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 20px;
  height: 150px;
}

.image-container {
  flex-shrink: 0;
  margin-right: 20px;
  height: -webkit-fill-available;
}

.image-container img {
  width: 200px;
  height: 150px;
  /* border-radius: 50%; */
  object-fit: cover;
  background-color: #f2f2f2;
}

.content {
  flex: 1;
  padding: 20px 0px;
}

.subtitle {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.product-name {
  font-size: 18px !important;
  font-weight: bold;
  margin: 5px 0;
}

.category-description {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* line-clamp: 2; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}


.price {
  font-size: 16px;
  font-weight: bold;
  color: #007BFF;
  margin: 0;
  padding-right: 10px;
  text-align: right;
}

.wishlist-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #ccc;
  cursor: pointer;
}

.wishlist-icon:hover {
  color: #ff5a5a;
}

@media (max-width: 768px) {
  .image-container img {
    width: 150px;
    height: 150px;
    /* border-radius: 50%; */
    object-fit: cover;
    background-color: #f2f2f2;
  }
}


.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 3px 10px;
  border: 1px solid #ccc;
  background: #fff;
  cursor: pointer;
}

.pagination button.active {
  background: #007bff;
  color: white;
  border-color: #007bff;
}