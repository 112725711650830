body {
  font-family: "Epilogue", sans-serif !important;
}

.step-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* background-color: #ddd; */
  background-color: #919191;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 1;
}

.step-label {
  margin-top: 5px;
  color: #999;
  text-align: center;
  font-size: 14px;
}

/* .step-connector {
    position: absolute;
    top: 50%;
    left: calc(100% - 15px);
    height: 2px;
    width: 100%;
    background-color: #ddd;
    z-index: 0;
  } */

.step-connector-background {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 67%;
  /* Adjust the width as needed */
  background-color: #919191;
  z-index: 0;
}

.step-connector-progress {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 67%;
  /* Adjust the width as needed */
  background-color: #919191;
  z-index: 0;
}


.step.active .step-number,
.step.active .step-connector {
  background-color: #007bff;
}

.step.active .step-label {
  color: #007bff;
}

.step:first-child .step-connector {
  display: none;
}