body {
  font-family: "Epilogue", sans-serif !important;
}

table {
  border: 1px solid #ebebeb;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}

table thead>tr {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
}

table tbody>tr td .ec-cart-pro-img {
  width: 100%;
  /* width: 80px; */
  height: 100px;
  object-fit: cover;
}

table tbody>tr td.ec-cart-pro-name a {
  color: #444444;
  font-weight: 400;
  text-decoration: none;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  letter-spacing: 0.6px;
  justify-content: center;
  align-items: center;
}

.table-content {
  box-shadow: 0 30px 70px #010f1c1a;
}

.table-content table tbody>tr td.ec-cart-pro-price {
  color: #555;
  font-weight: 500;
  font-size: 15px;
}

.table-content table input {
  border: none;
  width: 44px;
}

.table-content table input:focus-visible {
  outline: none;
}


.table-content tbody tr {
  border-bottom: 1px solid #E0E2E3;
}

.table-content tbody,
td,
tfoot,
th,
thead {
  padding: 10px;
}

.pr_name {
  display: none;
}

.removebtn {
  background: #121560;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px !important;
  color: white !important;
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (max-width:767px) {
  .pr_dtls {
    display: flex;
  }

  table tbody>tr td .ec-cart-pro-img {
    display: none;
    /* width: 50%; */
  }

  .pr_name {
    display: block;
    padding-right: 7px;
  }

  /* .removebtn{
    display: none;
  } */
  .table-content table {
    border: 1px solid #E0E2E3;
  }

  .table-content table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table-content table tbody>tr {
    border: 1px solid #ebebeb;
    display: block;
    margin-bottom: 15px;
  }

  .table-content table tbody>tr td {
    border-bottom: 1px solid #ebebeb;
    display: block;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    padding: 8px 10px;
  }

  /*
    .table-content table tbody > tr td.ec-cart-pro-name {
      width: 100%;
    }
  
   .table-content table tbody > tr td .ec-cart-pro-img {
      width: 100%;
    } */

  /* .table-content table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: capitalize;
      font-size: 16px;
      color: #444444;
      min-width: 90px;
      text-align: left;
    } */
}

.tilte {
  color: black;
  font-size: 14px;
}

.subtitle {
  font-size: 13px;
  color: #5e5c5c;
  font-weight: 400;
  display: block
}

.table-content a {
  color: #5e5c5c;
  font-size: 14px;
}

@media only screen and (max-width:767px) {
  .table-content table {
    border: none;
  }

  .subtitle {
    display: none
  }

  .table-content {
    box-shadow: 0 30px 70px #010f1c00;
  }
}

.ship-cart-shop {
  margin-top: 20px;
}

.ship-cart-shop .checkout-btn:hover {
  background-color: #ff7043;
}

.ship-cart {
  padding: 20px;
  box-shadow: 0 30px 70px #010f1c1a;
  background: #fff;
}

.ship-cart .pull-right {
  font-weight: 500;
  float: right !important;
}

.ship-cart h2 {
  font-size: 21px;
  font-weight: 700;
  border-bottom: 1px solid #E0E2E3;
  padding-bottom: 15px;
}

.ship-cart ul {
  padding-left: 0;
  list-style: none;
}

.ship-cart li {
  line-height: 30px;
}

.ship-cart li label {
  font-size: 16px;
  font-weight: 500;
}

.ship-total-price {
  padding-top: 10px;
  border-top: 1px solid #E0E2E3;
}

.ship-cart-checkout {
  font-size: 16px;
  font-weight: 500;
  color: rgb(9, 4, 19);
  background-color: #ffffff;
  display: block;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #000000;
  border-radius: 6px;
}

.ship-cart-checkout:hover {
  color: rgb(0, 0, 0);
  background-color: #dfb405;
}

.ship-cart-shopping {
  font-size: 16px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  background-color: #339104;
  display: block;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 6px;
}

.ship-cart-shopping:hover {
  color: white;
  background-color: #339104;
}

.qty-buttons {
  display: flex;
  align-items: center;
}

.qty-buttons button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #000000;
  /* Adjust padding as needed */
}

.qty-buttons button:hover {
  border-radius: 0;
  /* Makes the button round on hover */
}

/* .qty-buttons button.plus:hover {
  background-color: #cccccc;
}

.qty-buttons button.minus:hover {
  background-color: #cccccc;
} */

@media only screen and (max-width:767px) {
  .qty-buttons {
    display: contents;
  }

  .qty-buttons button {
    padding: 0px 4px;
  }
}




.trending-carousel-box {
  margin-top: 50px;
}

.slick-track {
  display: flex !important;
}

.slick-list {
  overflow: hidden;
}

.slick-slide {
  padding: 0px;
}

.slick-prev,
.slick-next {
  z-index: 2;
  top: 50%;
  width: 40px;
  height: 40px;
}

.shop2 {
  border: 1px solid #00000026;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px;
  /* padding: 10px; */
  background-color: #fff;
  text-align: center;
}


/* Desktop/Tablet View */
.cart-desktop-carousel .shop2 {
  border: 1px solid #00000026;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px;
  padding: 0px 0px;
  background-color: #fff;
  text-align: center;
}

.cart-desktop-carousel .shop-content {
  padding: 10px 5px 15px;
  text-align: start;
}

/* Mobile View */
.mobile-carousel {
  margin-bottom: 20px;
}

.mobile-carousel .mobile-shop1 {
  border: none;
  box-shadow: none;
  padding: 0;
  background-color: transparent;
  text-align: center;
}

.mobile-carousel .mobile-shop-content h3 {
  color: #000000d4;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 3px;
}

.mobile-carousel img {
  height: 120px;
}

.mobile-carousel .mobile-slide {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mobile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 2 items per row */
  gap: 10px;
  padding: 10px;
}

.mobile-item {
  background-color: white;
  padding: 10px;
  border: 1px solid #00000026;
  border-radius: 10px;
  text-align: center;
}

.mobile-shop-content h3 {
  color: #000000d4;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 3px;
}

/* Hide carousel based on screen size */
@media (max-width: 768px) {
  .cart-desktop-carousel {
    display: none;
  }

  .slick-prev {
    left: 0px !important;
  }

  .slick-next {
    right: 0px !important;
  }
}

@media (min-width: 769px) {
  .mobile-carousel {
    display: none;
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }
}

.qty-buttons-container-cart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 5px; */
}

.qty-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 5px; */
}

.qty-button {
  background-color: white;
  color: #ff7043;
  border: none;
  /* border-radius: 4px; */
  width: 25px;
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid;
}

.qty-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
}

input[type=number]:focus {
  /* border: none !important; */
  outline: 1px solid #ff7043;
  outline-offset: -2px;
}

.custom-confirm-button {
  background-color: #ff7043 !important;
  color: white;
  border: none !important;
  box-shadow: none !important;
}