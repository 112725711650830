body {
    font-family: "Epilogue", sans-serif !important;
}

.search-fluid {
    padding: 0px 100px !important;
    margin-top: 1%;
}

/* .product-grids {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    padding: 20px 0;
    margin: 0;
} */

.products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    gap: 20px;
}

.product-main-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    /* padding: 16px; */
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #fff;
    width: 100%;
}

.shop-content {
    padding: 10px 5px 15px;
    text-align: start;
}

.shop-content h3 {
    color: #000000d4;
    font-size: 18px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 3px;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    white-space: nowrap;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    text-align: center;
    transition: transform 0.3s ease;
    margin: 3px;
    height: 200px;
    width: 146px;
}

.product-image {
    width: 120px;
    height: 140px;
    object-fit: contain;
    border-radius: 8px;
}

.products-info {
    margin-top: 10px;
    width: 100%;
}

.product-namee {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.product-name:hover {
    color: #007185;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .product-grids {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }

    .search-fluid {
        padding: 0px 20px !important;
    }
}

@media (max-width: 480px) {
    .search-fluid {
        padding: 0px 20px !important;
    }

    .product-card {
        margin: 0px;
    }

    .product-grids {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
        gap: 10px;
        margin: 10px 0;
    }

    .product-image {
        padding: 0px;
        height: auto;
        object-fit: contain;
        border-radius: 8px;
    }

    .product-name {
        font-size: 10px;
        font-weight: 600;
        width: 100px;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.no-products-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-products-message img {
    margin-top: 100px;
    height: 300px;
    width: 300px;
}