body {
    font-family: "Epilogue", sans-serif !important;
}

.category-section {
    margin-bottom: 2rem;
    padding: 0px 100px !important;
    margin-top: 20px;
}

.category-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Grid layout for products */
.products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    gap: 20px;
}

/*individual product cards */
.product-main-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    /* padding: 16px; */
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #fff;
    width: 100%;
}

/* .category-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
} */

.product-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin-bottom: 8px;
}

/* See More button styling */
.see-more-container {
    text-align: center;
    margin-top: 16px;
}

.see-more-button {
    background-color: #beddff;
    color: #007bff;
    /* padding: 6px 8px; */
    padding: 8px 8px 6px 8px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 20px;
}

/* .see-more-button:hover {
    background-color: #0056b3;
} */

.shop-content {
    padding: 10px 5px 15px;
    text-align: start;
}

.shop-content h3 {
    color: #000000d4;
    font-size: 18px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 3px;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .category-section {
        padding: 0px 20px !important;
    }
}

.qty-buttons {
    display: flex;
    align-items: center;
}

.qty-buttons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    color: #ff7043;
}

.qty-buttons button:hover {
    border-radius: 0;
}

@media only screen and (max-width:767px) {
    .qty-buttons {
        display: contents;
    }

    .qty-buttons button {
        padding: 0px 4px;
    }
}

.image-containers {
    position: relative;
}

.ribbon {
    position: absolute;
    /* top: 10px; */
    /* left: 10px; */
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    /* border-radius: 4px; */
    z-index: 1;
}

.heart-icon {
    position: absolute;
    top: 0px;
    right: 5px;
    /* color: #ff7043; */
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
    transition: transform 0.2s ease-in-out;
}


.qty-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 5px; */
}

.qty-button {
    background-color: white;
    color: #ff7043;
    border: none;
    /* border-radius: 4px; */
    width: 25px;
    height: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid;
}

.qty-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input[type=number]:focus {
    /* border: none !important; */
    outline: 1px solid #ff7043;
	outline-offset: -2px;
}