body {
    font-family: "Epilogue", sans-serif !important;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: block !important
}

.owl-nav .owl-prev {
    border: none !important;
    background: none !important;
    font-size: 50px !important;
    position: absolute;
    top: 26%;
    left: .2%;
    background: white !important;
    width: 42px;
    height: 42px;
    text-align: center;
    border-radius: 0 3px 3px 0 !important;
    color: #6a6a6a !important;
    padding-top: 5px;
    transition: 0.25s;
    left: 0;
    clip: rect(-10px, 55px, 110px, 0);
    box-shadow: 0 1px 3px #888 !important;
}

.owl-nav .owl-next {
    position: absolute;
    top: 26%;
    right: .2%;
    background: white !important;
    width: 42px;
    height: 42px;
    text-align: center;
    font-size: 50px !important;
    border-radius: 3px 0 0 3px !important;
    color: #6a6a6a !important;
    padding-top: 5px;
    transition: 0.25s;
    border: none !important;
    box-shadow: 0 1px 3px #888 !important;
    right: 0;
    clip: rect(-10px, 42px, 110px, -35px);
}

.carousel-ht {
    background: brown;
    /*min-height: 550px;*/
}

.tp-slider-title {
    font-weight: 900;
    font-size: 56px;
    line-height: 1.07;
    color: white;
    margin-bottom: 12px;
}

.tp-slider-content>span {
    display: inline-block;
    font-size: 16px;
    color: white;
}

.tp-slider-content p {
    color: white;
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 40px;
    animation-delay: 0.7s;
    animation-duration: 1s;
}

.tp-slider-content p span {
    display: inline-block;
    color: #ffd43a;
    position: relative;
    z-index: 1;
}

.tp-slider-thumb {
    text-align: right;
}

@media screen and (max-width: 768px) {
    .tp-slider-title {
        font-weight: 900;
        font-size: 25px;
    }

    .tp-slider-content {
        margin-top: 30px;
    }

    .tp-slider-content p {
        font-size: 20px;
    }

    .tp-slider-thumb {
        text-align: right;
    }
}

.slider-related-content {
    font-weight: 700;
    color: #874b5f;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px #000000a3;
}

/*----- carousel start ------*/
.owl-nav .owl-prev {
    border: none !important;
    background: none !important;
    box-shadow: 1px 1px 1px transparent !important;
    font-size: 85px !important;
    position: absolute;
    top: 22%;
    left: -1.4%;
    background: #fff;
    height: 40px;
    text-align: center;
    width: 40px;
    border-radius: 40px;
    color: #56354d !important;
    padding-top: 5px;
    transition: 0.25s;
    box-shadow: 0px 10px 25px rgb(0 0 0 / 25%);
}

.owl-nav .owl-next {
    position: absolute;
    top: 22%;
    right: -0.4%;
    background: #fff;
    height: 40px;
    text-align: center;
    width: 40px;
    border-radius: 40px;
    color: #56354d !important;
    padding-top: 5px;
    transition: 0.25s;
    box-shadow: 0px 10px 25px rgb(0 0 0 / 25%);
    border: none !important;
    background: none !important;
    box-shadow: 1px 1px 1px transparent !important;
    font-size: 85px !important;
}

.owl-nav .owl-prev {
    font-size: 20px;
}

.owl-nav>div {
    margin-top: -10px;
    position: absolute;
    top: 150px;
    color: #56354d;
}

.carousel-box button:focus {
    outline: none;
}

.owl-carousel .owl-item img {
    height: 275px;
    margin-top: 50px;
}

@media screen and (max-width:768px) {
    .owl-nav .owl-prev {
        top: 30%;
        font-size: 55px !important;

        /* color: rgb(0, 0, 0) !important; */
    }

    .owl-carousel .owl-item img {
        /* height: auto; */
        margin-top: 0px;
    }

    .owl-nav .owl-next {
        top: 30%;
        font-size: 55px !important;
        /* color: white !important; */
    }
}

.carousel-box {
    background: #e1e1e130;
}