/* .footer {
    background: #000000;
    padding: 2rem 0 2rem 0;
  }
  
  .footer h2 {
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
  }

  .footer-content{
    color: white;
    margin: 10px 0;
  }
  
  .footer-content li {
    color: white;
    padding: 2px 0px;
  }
  
  .footer-content li a {
    font-size: 14px;
    color: #fff;
    transition: all .3s ease-out 0s;
  }
  
  .footer-content li:hover a {
    padding-left: 8px;
    color: #009aff;
  }
  
  .footer .social-links {
    margin-top: 20px;
  }
  
  .footer .social-links a {
    color: #000;
    background-color: #fff;
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    text-align: center;
    line-height: 36px;
    transition: all 0.5s ease;
    border: solid 2px #eee;
  }
  
  .footer .social-links a:hover {
    color: #ffffff;
    background-color: #151515;
  }
  
  .footer .form-control {
    padding: 0.6rem 0.75rem;
  }
  
  .footer .form-control:focus {
    outline: 0;
    box-shadow: none;
    border: none;
  }
  
  .footer .input-group-btn {
    position: relative;
  }
  
  .btn-newsletter {
    background-color: #009aff;
    border-color: #009aff;
    margin: 0;
    text-transform: none;
    padding: 11px 22px;
    border-radius: 0 8px 8px 0;
  }
  
  .btn-newsletter {
  font-size: 14px;
  color: white;
  font-weight: 500;
  }
  
  .input-newletter {
  border: none !important;
  background-color: #fff;
  border-radius: 4px 0 0 4px;
  }
  
  .btn-newsletter:hover, .btn-newsletter:focus, .btn-newsletter:active, .btn-newsletter:focus-visible {
  color: #fff !important;
  background-color: #009aff !important;
  border-color: #009aff !important;
  } */

body {
  font-family: "Epilogue", sans-serif !important;
}

.footer {
  background-color: #f8f9fa;
  padding: 0px 100px;
  font-family: Arial, sans-serif;
  color: #333;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin: 10px;
  text-align: left;
  padding-left: 40px;
}

.footer-section h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #000;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin: 5px 0;
  color: #000;
}

.footer-section ul li a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.payment-icons img,
.qr-code img {
  width: 80px;
  margin: 5px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 20px auto 0;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  flex-wrap: wrap;
}

.footer-bottom a {
  cursor: pointer;
}

.language-select {
  padding: 5px;
}

.social-icons a {
  margin: 0 5px;
  color: #333;
  text-decoration: none;
  font-size: 18px;
}

.social-icons a:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .footer {
    padding: 0px 20px;
  }
}