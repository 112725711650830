/* .header-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #f5f5f5;
}

.address-search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 100px;
}

.address {
    flex: 1;
    text-align: left;
}

.search-bar {
    flex: 1;
    text-align: right;
}

.search-input {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 20px;
} */

body {
    font-family: "Epilogue", sans-serif !important;
}

/* Mobile View Styles */
@media (max-width: 768px) {
    .header-section {
        /* margin-top: 10px; */
        padding: 5px 0;
    }

    .address-search-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 15px;
        /* margin-top: 40px; */
    }

    .address {
        text-align: left;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .search-bar {
        text-align: left;
        width: 100%;
    }

    .search-input {
        padding: 8px;
        width: 100%;
        border-radius: 15px;
    }

    .banner1 {
        width: 100%;
        height: 250px !important;
        padding: 10px 20px !important;
    }
}

/* Smaller Mobile Screens */
@media (max-width: 553px) {
    .header-section {
        /* margin-top: 10px; */
        padding: 5px 0;
    }

    .address-search-container {
        flex-direction: column;
        padding: 8px 10px;
        /* margin-top: 40px; */
    }

    .address {
        font-size: 12px;
        margin-bottom: 8px;
    }

    .search-bar {
        width: 100%;
        text-align: left;
    }

    .search-input {
        padding: 6px;
        width: 100%;
        border-radius: 10px;
        font-size: 12px;
    }
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(255, 255, 255, 0.8);  */
    background: rgba(128, 128, 128, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    pointer-events: all;
    /* Blocks interaction below */
}

/* .homepage-container {
  pointer-events: none; 
}

.homepage-container.loaded {
  pointer-events: auto; 
} */

.no-products {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 200px;
}

.banner1 {
    width: 100%;
    height: 350px;
    padding: 10px 100px;
}