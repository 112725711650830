body {
    font-family: "Epilogue", sans-serif !important;
}

.card {
    margin-top: 200px;
}

.btn {
    margin-top: 20px;
}

.dlvr_adds {
    margin-top: 0px;
    /* min-height: 500px; */
}

.dlvr_contt {
    padding: 20px;
    box-shadow: 0 30px 70px #010f1c1a;
}

.dlvr_contt h2 {
    font-size: 18px;
}

.dlvr_contt p {
    color: gray;
    font-size: 15px;
}

.dlvr_contt .form {
    display: grid;
    grid-gap: 0rem;
}

.fields--2 {
    grid-template-columns: 1fr 1fr;
}

.fields {
    display: grid;
    grid-gap: 1rem;
}

.field {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    border-radius: .25rem;
}

.field__label {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.field__input {
    padding: 6px 10px;
    margin: 0;
    border: 0;
    outline: 0;
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background-color: #f3f3f3;
}

.field__option {
    padding: 6px 10px;
    margin: 0;
    border: 0;
    outline: 0;
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    color: #555;
    background-color: #f3f3f3;
}

.ship-cart {
    padding: 20px;
    box-shadow: 0 30px 70px #010f1c1a
}

.ship-cart .pull-right {
    font-size: 15px;
    color: #555;
    float: right !important;
}

.ship-cart h2 {
    font-size: 17px;
    padding-bottom: 15px;
}

.ship-cart ul {
    padding-left: 0;
    list-style: none;
}

.ship-cart li {
    line-height: 40px;
}

.ship-cart li label {
    font-size: 14px;
    font-weight: 500;
    color: #555;
}

.ship-total-price {
    padding-top: 10px;
    border-top: 1px solid #E0E2E3;
}

.ship-cart-checkout {
    font-size: 16px;
    font-weight: 500;
    color: white;
    background-color: #000;
    display: block;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
}

.ship-cart-checkout:hover {
    color: white;
    background-color: #0989ff;
}


/* Styles for the addresses container  */
.dlvr_contt {
    background-color: #f8f9fa !important;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

/* Styles for each address form-group */
.form-group {
    margin-bottom: 15px;
    /* padding: 10px; */
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0px 4px rgba(11, 11, 11, 25%);
    display: flex;
    justify-content: space-between;
}

/* Styles for labels */
.form-group label {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    word-wrap: break-word;
}

/* Styles for input radio buttons */
.form-group input[type="radio"] {
    margin-left: 10px;
}

@media screen and (max-width: 768px) {
    .form-group {
        display: block;
    }
}


.slick-track {
    display: flex !important;
}

.slick-list {
    overflow: hidden;
}

.slick-slide {
    padding: 0px;
}

.slick-prev,
.slick-next {
    z-index: 2;
    top: 50%;
    width: 40px;
    height: 40px;
}


/* Desktop/Tablet View */
.desktop-carousel .shop1 {
    border: 1px solid #00000026;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px;
    padding: 0px 0px;
    background-color: #fff;
    text-align: center;
}

.desktop-carousel .shop-content {
    padding: 10px 5px 15px;
    text-align: start;
}

/* Mobile View */
.mobile-carousel .mobile-shop1 {
    border: none;
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    text-align: center;
}

.mobile-carousel .mobile-shop-content h3 {
    color: #000000d4;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 3px;
}

.mobile-carousel img {
    height: 120px;
}

.mobile-carousel .mobile-slide {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.mobile-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 2 items per row */
    gap: 10px;
    padding: 10px;
}

.mobile-item {
    background-color: white;
    padding: 10px;
    border: 1px solid #00000026;
    border-radius: 10px;
    text-align: center;
}

.mobile-shop-content h3 {
    color: #000000d4;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 3px;
}

/* Hide carousel based on screen size */
@media (max-width: 768px) {
    .desktop-carousel {
        display: none;
    }

    .slick-prev {
        left: 0px !important;
    }

    .slick-next {
        right: 0px !important;
    }
}

@media (min-width: 769px) {
    .mobile-carousel {
        display: none;
    }

    .slick-prev {
        left: -50px;
    }

    .slick-next {
        right: -50px;
    }
}