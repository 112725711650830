body {
  font-family: "Epilogue", sans-serif !important;
}

.blue-link {
  color: rgb(64, 137, 210);
  /* Set the color to blue */
  text-decoration: underline;
  /* Add underline */
}

.payment-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.payment-success-heading {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}