.account-page {
    display: flex;
    font-family: Arial, sans-serif;
    padding: 0px 100px;
}

.sidebar {
    width: 35%;
    background-color: #f4f4f4;
    padding: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.profile-section {
    text-align: center;
    margin-bottom: 20px;
}

.avatar {
    font-size: 50px;
    margin-bottom: 10px;
}

h3 {
    font-size: 18px;
}

.main-content {
    /* flex-grow: 1; */
    padding: 20px 0px;
    min-width: 65%;
}

.main-content h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}

.form-groups {
    margin-bottom: 20px;
}

.form-groups input {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 90%;
    color: black;
}

.form-groups label {
    display: block;
    margin-bottom: 5px;
}

.form-groups p {
    margin-bottom: 10px;
}

.edit-button-box {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 10%;
}

.edit-button {
    background-color: #fff;
    color: #ff7043;
    border: 1px solid #ff7043;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.btns {
    padding: 10px 15px;
    background-color: #ff7043;
    width: 75%;
    color: #fff;
    justify-content: space-between;
    display: flex;
    border-radius: 30px;
    cursor: pointer;
    align-items: center;
}

.account-handle-btn {
    color: #ff7043;
    cursor: pointer;
    font-weight: 600;
}

.account-handle-btn:hover {
    text-decoration-line: underline;
}