body {
  font-family: "Epilogue", sans-serif !important;
}

.dlvr_adds {
  margin: 2rem 1rem;
}

@media screen and (max-width:768px) {
  .dlvr_adds {
    /*display: none;*/
  }

}

.dlvr_cont {
  padding: 20px;
  box-shadow: 0 30px 70px #010f1c1a;
  border: 1px solid #eee;
  background: white;
  /*margin: 25px 0;*/
}

.dlvr_cont h2 {
  font-size: 18px;
}

.dlvr_cont p {
  color: gray;
  font-size: 15px;
}

.dlvr_cont .form {
  display: grid;
  grid-gap: 0rem;
}

.fields--2 {
  grid-template-columns: 1fr 1fr;
}

.fields {
  display: grid;
  grid-gap: 1rem;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  border-radius: .25rem;
}

.field__label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.field__input {
  padding: 6px 10px;
  margin: 0;
  border: 0;
  outline: 0;
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  /*background-color: #f3f3f3;*/
  border: 1px solid #eee;
}

.field__select {
  padding: 6px 10px;
  margin: 0;
  border: 0;
  outline: 0;
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  /*background-color: #f3f3f3;*/
  color: gray;
  border: 1px solid #eee;
}

.field__option {
  padding: 6px 10px;
  margin: 0;
  border: 0;
  outline: 0;
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  color: #555;
  background-color: #f3f3f3;
}

.ship-cart {
  padding: 20px;
  box-shadow: 0 30px 70px #010f1c1a
}

.ship-cart-pymnt {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ff9800;
  padding: 10px;
  border-radius: 3px;
  margin: 6px 0 20px 0;
  background: #ff98000a;
}

.ship-cart-pymnt span {
  font-size: 14px;
  font-weight: 600;
  color: #ff9800;
  padding-left: 8px;
}

.ship-cart-pymnt label {
  font-size: 14px;
  font-weight: 600;
  color: #ff9800;
  line-height: 26px;
}

.ship-cart .pull-right {
  font-size: 14px;
  font-weight: 700;
  float: right !important;
}

.ship-cart h2 {
  font-size: 17px;
  /*padding-bottom: 15px;*/
}

.ship-cart h3 {
  font-size: 15px;
}

.ship-cart ul {
  padding-left: 0;
  list-style: none;
}

.ship-cart li {
  line-height: 30px;
}

.ship-cart li label {
  font-size: 14px;
  /*font-weight: 500;
              color: #555;*/
}

.ship-total-price {
  padding-top: 10px;
  border-top: 1px solid #E0E2E3;
}

.ship-cart-checkout {
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: #000;
  display: block;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
}

.ship-cart-checkout:hover {
  color: white;
  background-color: #0989ff;
}

.field:focus-within {
  border-color: #000;
}

.fields--2 {
  grid-template-columns: 1fr 1fr;
}

.fields--3 {
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width:768px) {
  .fields--2 {
    grid-template-columns: 1fr;
  }

  .fields--3 {
    grid-template-columns: 1fr;
  }

  .fields {
    grid-gap: 0rem;
  }

}

.shipping-check {
  margin: 30px 10px;
}

.shipping-check label {
  font-size: 14px;
  margin-left: 8px !important;
}

.form__confirmation {
  text-align: right
}

.btn_confirm1 {
  background: #ff9800;
  padding: 6px 24px;
  border: 1px solid #ff9800;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-radius: 2px;
  transition: 0.4s;
}

.btn_confirm2 {
  background: black;
  padding: 6px 24px;
  border: 1px solid black;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-radius: 2px;
  transition: 0.4s;
}

.btn_confirm1:hover {
  background: white;
  color: black;
  border: 1px solid black;
}

.btn_confirm2:hover {
  background: white;
  color: black;
  border: 1px solid black;
}

.choose-pymnt {
  margin-bottom: 4px;
  font-size: 12px;
  color: #0989ff;
}

.retrnPrd_Image {
  border-radius: 5px;
  margin: 8px 0;
  background: white;
  position: relative;
  text-align: center;
  display: block;
}

.retrnPrd_soverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: .5s ease;
  background-color: #8c8e8f14;
}

.retrnPrd_cont p {
  font-size: 16px;
  font-weight: 500;
  color: black;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
}

.return_text {
  width: 100%;
  margin: 10px 0;
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  border: 1px solid #80808052;
  padding: 14px;
  border-radius: 4px;
}

textarea:focus {
  outline: none;
  border-color: #808080c2;
}

.orderInfo {
  display: block;
  justify-content: space-between;
  border-bottom: 1px solid #0000000f;
  padding-bottom: 0px;
  margin-bottom: 41px;
}

.orderDetails {
  display: flex;
}

.orderId {
  background: #a3a0a01a;
  padding: 12px 26px;
  border-radius: 30px;
  display: inline;
}

.orderName {
  font-weight: 700;
}

.orderIdinfo {
  font-weight: 500;
  color: #0d6efd;
  padding-left: 5px;
}

.orderDate {
  line-height: 44px;
  padding-left: 24px;
}

.orderDate span {
  color: #5c5c5ca3;
  font-weight: 500;
}

.orderTrack {
  line-height: 42px;
}

.orderTrack span {
  background: #ff9800;
  padding: 12px 26px 12px 13px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 14px;
  color: white;
}

/*----------*/
.order_cart_page .order_cart_body table {
  border: none;
  width: 100%;
}

.order_cart_page .order_cart_body table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 15%);
}

.order_cart_page .order_cart_body table tbody tr:last-child {
  border-bottom: none;
}

.order_cart_page .order_cart_body table tbody td {
  padding: 30px 12px;
}

.order_cart_page .order_cart_body td,
.order_cart_page .order_cart_body th {
  color: #333333;
  border: none;
  padding: 16px;
}

.order_cart_page .order_cart_body .item-media .cart-image {
  display: flex;
}

.order_cart_page .order_cart_body .item-media img {
  padding: 2px;
  background-color: #fff;
  border: 1px solid rgba(0 0 0 / 10%);
  border-radius: 5px;
}

.order_cart_page .order_cart_body .item-media .item-info {
  width: calc(100% - 100px);
  padding-inline-start: 30px;
  position: relative;
}

.order_cart_page .order_cart_body .item-media .item-info .item-title {
  color: #333333;
  font-size: 22px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: none;
  padding-right: 7rem
}

.order_cart_page .order_cart_body .item-media .item-info .item-price {
  padding-bottom: 10px;
}

.order_cart_page .order_cart_body .item-media {
  width: 55%;
}

.order_cart_page .order_cart_body .item-price span {
  font-size: 24px;
  color: #ff9800;
  font-weight: 700;
}

.order_cart_page .order_cart_body .item-date span {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}

.order_cart_page .order_cart_body .item-media .item-info .item-option {
  display: flex;
  flex-direction: column;
}

.order_cart_page .order_cart_body .item-media .item-info .item-option .swatch-item {
  padding-bottom: 10px 0px;
}

.order_cart_page .order_cart_body .item-media .item-info .item-option .swatch-item .heading {
  color: #333333;
  font-weight: 600;
}

@media (max-width:767px) {
  .order_cart_page .order_cart_body table tbody tr {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }

  .order_cart_page .order_cart_body .item-media {
    width: 100%;
  }

  .order_cart_page .order_cart_body .item-media .item-info .item-title {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 0rem;
  }

  .order_cart_page .order_cart_body table tbody td {
    padding: 8px 0;
  }

}

/*----------*/
.odr_bottom {
  padding: 20px;
  box-shadow: 0 30px 70px #010f1c1a;
  background: white;
  display: flex;
  justify-content: space-between;
}

.odr_bottom_info {
  display: flex;
}

.odr_bottom_custom_button {
  position: relative;
  color: black;
  text-decoration: none;
}

.odr_bottom_custom_button:hover {
  color: black;
}

.odr_bottom_custom_button .odr_tooltip {
  visibility: hidden;
  width: 305px;
  background: #fff;
  color: rgb(19, 20, 20);
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  right: 82px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 22px 0;
}

.odr_bottom_custom_button:hover .odr_tooltip {
  visibility: visible;
  opacity: 1;
}

.or_tltip_cont1 {
  padding: 10px 22px;
}

.or_tltip_cont2 {
  padding: 10px 22px;
}

.or_tltip_cont3 {
  padding: 10px 22px;
}

.or_tltip_cont4 {
  padding: 10px 22px;
}

.or_tltip_cont3 {
  border-top: 1px solid rgba(0, 0, 0, 15%);
  padding: 12px;
}

.or_tltip_cont3_pr {
  display: flex;
  justify-content: space-between;
}

.or_tltip_cont4 {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 15%);
  padding: 12px;
}

.oe_tip_31 {
  font-size: 17px;
  font-weight: 400;
}

.oe_tip_41 {
  font-size: 18px;
  font-weight: 700;
}

.oe_tip_42 {
  font-size: 18px;
  font-weight: 500;
}

.or_tltip_cont1 div:first-child {
  font-weight: 400;
  font-size: 17px;
}

.or_tltip_cont1 div:last-child {
  font-weight: 700;
  font-size: 22px;
}

.or_tltip_cont2 div:first-child {
  font-weight: 400;
  font-size: 17px;
}

.or_tltip_cont2 div:last-child {
  font-weight: 700;
  font-size: 22px;
}

#tracking {
  margin-bottom: 1rem;
}

.or_tracking-item {
  border-left: 2px solid #ff9800;
  position: relative;
  /* padding: 2rem 1.5rem 0.5rem 2.5rem; */
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}

.or_tracking-item:last-child {
  padding-bottom: 4rem;
  border: none
}

.or_tracking-item .or_tracking-date {
  margin-bottom: 0.5rem;
  position: absolute;
  left: -12px;
  top: 43px;
}

.or_tracking-item .or_tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}

.or_tracking-item .or_tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}

.or_tracking-item .or_tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}

.or_tracking-item .tracking-icon {
  position: absolute;
  left: -26px;
  width: 3.1rem;
  height: 3.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: #ff9800;
  color: #fff;
  box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.or_tracking-item-pending {
  border-left: 2px dotted #d6d6d6;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;

}

.or_tracking-item:first-child {
  padding-top: 0rem;
  min-height: 0rem;

}

.or_tracking-item:first-child .or_tracking-date {
  position: absolute;
  left: -12px;
  top: 12px;
}

.or_tracking-item-pending .or_tracking-date {
  margin-bottom: 0.5rem;
  position: absolute;
  left: -12px;
  top: 43px;
}

.or_tracking-item-pending .or_tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}

.or_tracking-item-pending .or_tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}

.or_tracking-item-pending .or_tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}

.or_tracking-item-pending .tracking-icon {
  line-height: 2.6rem;
  position: absolute;
  left: -26px;
  width: 3.1rem;
  height: 3.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  color: #d6d6d6;
  background: #d6d6d6;
  box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.or_tracking-item-pending .or_tracking-content {
  font-weight: 600;
  font-size: 17px;
}

.or_tracking-item .tracking-icon.status-current {
  left: -32px;
  width: 3.9rem;
  height: 3.9rem;
  top: 28px;
}

.or_tracking-item .tracking-icon.status-intransit {
  color: #00ba0d;
  font-size: 0.6rem;
}

.or_tracking-item .tracking-icon.status-current {
  color: #00ba0d;
  font-size: 0.6rem;
}

@media (min-width: 992px) {
  .or_tracking-item .or_tracking-date {
    position: absolute;
    left: -12px;
    top: 12px;
  }

  .or_tracking-item .or_tracking-date span {
    display: block;
  }

  .or_tracking-item .or_tracking-content {
    padding: 0;
    background-color: transparent;
  }

  .or_tracking-item-pending .or_tracking-date {
    position: absolute;
    left: -13px;
    top: 44px;
  }

  .or_tracking-item-pending .or_tracking-date span {
    display: block;
  }

  .or_tracking-item-pending .or_tracking-content {
    padding: 0;
    background-color: transparent;
  }
}

.or_tracking-item .or_tracking-content {
  font-weight: 600;
  font-size: 17px;
  padding-left: 50px;
}

/* ===========view more checkbox============ */

/* .hidden {
  display: none;
  }
  
  .view-more:not(:checked) ~ label,
  .view-more:checked ~ .hidden {
  display: block;
  }
  
  .view-more,
  .view-more:checked ~ label {
  display: none;
  } */

/* --------------------- */

.rs_order {
  padding: 1rem 0 1rem 0;
  display: none;
}

.rs_order .order_cart_page {
  padding-left: 20px;
  padding-right: 10px;
}

.rs_order .orderTrack {
  padding-left: 16px;
}

.rs_order .orderTrack span {
  background: none;
  padding: 6px 13px 6px 7px;
  font-weight: 500;
  font-size: 15px;
  color: black;
}

@media (max-width:768px) {
  .rs_order {
    display: block
  }
}

.description {
  /* font-family: Arial, sans-serif; */
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  max-height: 360px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.description span {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

.description br {
  margin-bottom: 10px;
}

.description span:hover {
  text-decoration: underline;
}