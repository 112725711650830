body {
  font-family: "Epilogue", sans-serif !important;
}

.whislist {
  padding-top: 30px;
  /* min-height: 1000px; */
}

.wishlist-title {
  text-align: start;
  font-size: 26px;
  font-weight: 600;
  padding-top: 32px;
  padding-bottom: 20px;
}

.sidebar_widget.wishlist .cart_item {
  padding: 12px;
  border: 0;
}

.cart_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 10px 70px #010f1c1a;
  margin: 15px 0;
  background: #fff;
}

.sidebar_widget.wishlist .cart_img {
  width: 10%;
  margin-right: 18px;
  text-align: center;
}

.cart_info {
  width: 65%;
}

.cart_info a {
  display: block;
  color: #333;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 600;
  /* line-height: 17px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;

}

/* .cart_info span */
.cart_price {
  font-size: 16px;
  font-weight: 700;
  display: block;
  line-height: 20px;
  padding: 6px 0 1px;
  /* color: #00bba6; */
  color: #007bff;
}

.cart_info span.quantity {
  font-size: 14px;
  line-height: 20px;
  display: block;
  font-weight: 400;
  color: #333;
}

.cart_desc {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.cart_remove {
  /* width: 5%; */
  width: 10%;
  text-align: center
}

.cart_remove a {
  color: #00bba6;
  font-size: 20px;
}

.cart_img img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.cart_add {
  width: 12%;
  /* text-align: right; */
  text-align: -webkit-right;
}

.add_item {
  background: #2c8de9;
  padding: 8px 12px;
  color: white;
  font-weight: 600;
  text-decoration: none;
  border-radius: 20px;
}

/* .add_item:hover {
  color: white;
  background: #055fb1;
} */

@media screen and (max-width: 768px) {
  .sidebar_widget.wishlist .cart_img {
    width: auto;
    margin-right: 18px;
    text-align: center;
  }

  .cart_item {
    display: block;
  }

  .cart_remove {
    display: none;
  }

  .cart_info {
    width: auto
  }

  .cart_add {
    width: auto
  }

  .add_item {
    padding: 5px 6px;
    font-size: 12px;
  }
}

.image-containers {
  position: relative;
}

.wishlist-ribbon {
  position: absolute;
  background-color: red;
  color: white;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: 600;
  z-index: 1;
}