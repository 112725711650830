body {
  font-family: "Epilogue", sans-serif !important;
}

.v-center {
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* header */
.header .row>* {
  width: auto;
}

.profile-icon a {
  color: #fff !important;
}

.header {
  padding: 0px 90px;
  /* display: block; */
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  /* padding: 6px 15px; */
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.address-search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 125%;
  /* margin-top: 0; */
  padding: 10px 15px;
  visibility: visible;
  opacity: 1;
  margin-top: 10px;
}

.home-icon {
  margin-top: 9px;
  margin-left: 50px;
  margin-right: 8px;
}

.home-icon svg {
  fill: #fff;
  font-size: 24px;
}

.address {
  flex: 0.4 1;
  text-align: left;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.search-bar {
  flex: 1 1;
  text-align: right;
}

.search-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #888;
}

.search-input {
  padding: 10px 10px 10px 35px;
  width: 100%;
  /* max-width: 500px; */
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 15px;
  box-sizing: border-box;
}

@media(max-width: 430px) {
  .header {
    padding: 6px 20px;
  }
}

.header .item-left {
  flex: 0 0 8%;
}

.header .logo {
  width: 65px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .logo a {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.header .item-center {
  flex: 0 0 58%;
}

.header .item-right {
  /* flex: 0 0 25%; */
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}

.header .item-right a {
  text-decoration: none;
  font-size: 16px;
  color: #555555;
  display: inline-block;
  margin-left: 7px;
  transition: color 0.3s ease;
}

.header .menu>ul {
  margin-bottom: 00px;
}

.header .menu>ul>li {
  display: inline-block;
  line-height: 50px;
  margin-left: 25px;
}

.header .menu>ul>li>a {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  text-transform: capitalize;
  transition: color 0.3s ease;
}

.header .menu>ul>li>a:hover {
  color: #c3c4c4;
}

.header .menu>ul>li .single-column-menu {
  min-width: 280px;
  max-width: 350px;
}

/* banner section */
.banner-section {
  /*background-image: url('../img/banner.jpg');*/
  background-size: cover;
  background-position: center;
  height: 700px;
  width: 100%;
  display: block;
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/*responsive*/
@media(max-width: 991px) {

  .header .item-center {
    order: 3;
    flex: 0 0 100%;
  }

  .header .item-left {
    padding: 0px;
  }

  .header .item-left,
  .header .item-right {
    flex: 0 0 auto;
  }

  .v-center {
    justify-content: space-between;
  }

  .header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    /* margin-right: 15px; */
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: #ffffff;
    width: 24px;
    position: relative;
  }

  .header .mobile-menu-trigger span:before,
  .header .mobile-menu-trigger span:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  .header .mobile-menu-trigger span:before {
    top: -6px;
  }

  .header .mobile-menu-trigger span:after {
    top: 6px;
  }

  .header .item-right {
    align-items: center;
  }

  .header .menu {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    /* left: 0; */
    right: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    /* transform: translate(-100%); */
    transform: translate(100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }

  .header .menu.active {
    transform: translate(0%);
  }

  .header .menu>ul>li {
    line-height: 1;
    margin: 0;
    display: block;
  }

  .header .menu>ul>li>a {
    color: #000000;
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header .menu>ul>li>a i {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 50px;
    transform: rotate(-90deg);
  }

  .header .menu .mobile-menu-head {
    display: flex;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background-color: #ffffff;
    top: 0;
  }

  .header .menu .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: none;
  }

  .header .menu .mobile-menu-head.active .go-back {
    display: block;
  }

  .header .menu .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }

  .header .menu .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 25px;
  }

  .header .menu .menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

@media(max-width: 380px) {
  .header .mobile-menu-trigger {
    margin-right: 2px;
  }

  .cart-icon {
    margin-right: 0px !important;
  }

  .header .item-right a {
    margin-left: 0px;
  }
}

.cart-icon {
  margin-top: 9px;
  /* margin-right: 34px; */
  height: 30px;
  width: 30px;
}

.signin-btn {
  margin-top: 6px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  font-weight: 500;
  border-radius: 20px;
  padding: 6px 12px;
  /* Adjusted padding */
  font-size: 14px;
  /* Adjusted font size */
  line-height: 18px;
  /* Adjusted line height */
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  min-width: 30px;
  /* Adjusted min-width */
}

.signin-btn:hover {
  color: #c3c4c4;
}

@media(max-width: 992px) {
  .signin-btn {
    margin-top: 0px;
  }
}

@media(max-width: 380px) {
  .signin-btn {
    margin-top: 0px;
    padding: 6px 12px 6px 5px;
  }
}

.display-picture {
  /* margin-left: auto; */
  margin-left: 30%;
  cursor: pointer;
}

.display-picture img {
  width: 22px;
  border-radius: 50%;
  margin-left: 19px;
  margin-right: 6px;
  border: 2px solid #fff;
}

.display-picture img:hover {
  border: 2px solid #E78341;
}

.display-p-card {
  opacity: 0;
}

.display-p-card ul {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: #ffffff;
  position: absolute;
  top: 4rem;
  right: 0rem;
  border-radius: 2px;
  padding: 10px 50px 10px 20px;
}

.display-p-card ul li {

  padding: 5px 20px 5px 15px;
  color: #FFF;
  font-size: 14px;
}

.hidden {
  display: none;
}

.user-info {
  display: flex;
  align-items: center;
  margin-top: 3px;

}

.user-name {
  color: white;
  margin-left: 10px;
  display: none;
  /* Adjust as needed for spacing */
}

@media(max-width: 991px) {
  .user-name {
    display: none;
  }

  .display-picture img {
    width: 20px;
  }

  .cart-icon {
    margin-top: 5px;
    margin-right: 6px;
  }

  .home-icon {
    display: none;
  }
}

@media(max-width: 380px) {

  .display-picture img {
    margin-left: 6px;
    margin-right: 10px;
    width: 20px;
  }
}

@media(min-width: 991px) and (max-width: 1200px) {
  .header .item-right {
    flex: 0 0 20%;
  }
}


@media (max-width: 768px) {
  .header-section {
    /* margin-top: 10px; */
    padding: 5px 0;
  }

  .address-search-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 15px;
    /* margin-top: 40px; */
  }

  .address {
    text-align: left;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .search-bar {
    text-align: left;
    width: 100%;
  }

  .search-input {
    padding: 8px 8px 8px 35px;
    width: 100%;
    border-radius: 15px;
  }
}

/* Smaller Mobile Screens */
@media (max-width: 553px) {
  .header {
    padding: 5px;
    height: auto;
  }

  .address-search-container {
    display: flex;
    flex-direction: row;
    padding: 0px 0px !important;
    /* margin-top: 40px; */
  }

  .address {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .search-bar {
    width: 100%;
    text-align: left;
  }

  .search-input {
    padding: 6px;
    width: 100%;
    border-radius: 10px;
    font-size: 12px;
  }

  .menu-big-screen {
    display: none !important;
  }
}

@media(min-width: 992px) {
  .menu-main {
    display: none;
  }
}

@media(max-width: 991px) {
  .menu-main {
    display: block;
  }
}


@media(min-width: 992px) {
  .header {
    flex-wrap: nowrap;
    height: auto;
  }

  .address-search-container {
    margin-top: 0;
    display: flex;
  }
}

/* Mobile View Adjustments */
@media(max-width: 991px) {
  .header {
    flex-wrap: wrap;
    height: auto;
    padding: 0px 10px;
  }

  .header-item.item-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 5px;
  }

  .address-search-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
    padding: 10px 15px;
    display: block;
    visibility: visible;
    opacity: 1;
  }

  .address {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    color: #000;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
  }

  .search-icon {
    left: 3%;
  }

  .search-input {
    padding: 10px 10px 10px 35px;
    width: 100%;
    border-radius: 15px;
    font-size: 14px;
    border: 1px solid #ccc;
    margin-left: 0px;
  }

  .suggestions-list {
    width: 93% !important;
  }
}

@media(max-width: 380px) {
  .address {
    font-size: 11px;
    margin-bottom: 4px;
  }

  .search-input {
    padding: 6px;
    font-size: 11px;
  }
}

.dropdown-nav-buttons {
  position: absolute;
  width: max-content;
  border-radius: 20px;
  overflow: hidden;
  top: 100%;
  right: 16%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-nav-item {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-nav-item:hover {
  background-color: #f5f5f5;
}




.suggestions-list {
  background: white;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 43%;
  z-index: 1000;
  text-align: left;
  margin-left: 1%;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
}