body {
    font-family: "Epilogue", sans-serif !important;
}

.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #D3D3D3;
    /* padding-top: 2%; */
}

.signup-card {
    background-color: white;
    border-radius: 20px;
    padding: 10px 30px;
    width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.logo img {
    width: 50px;
    margin-bottom: 15px;
}

.signup-card h2 {
    font-size: 24px;
    margin: 0;
    text-align: left;
}

.signup-card p {
    color: #666;
    margin: 10px 0 20px;
    text-align: left;
}

.form-group {
    position: relative;
    margin-bottom: 15px;
    padding: 0px;
}

.form-group input {
    width: 100%;
    padding: 7px 40px 7px 35px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: black;
}

.submit-btn {
    width: 100%;
    background-color: #ff7043;
    color: white;
    padding: 8px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.signin-link {
    margin-top: 15px;
    font-size: 14px;
    text-align: center !important;
    font-weight: 600;
}

.signin-link a {
    color: #007bff;
    text-decoration: none;
}

.signin-link a:hover {
    text-decoration: underline;
}

.forget {
    text-align: right;
    color: #0380cf;
    font-size: 12px;
    padding: 5px 0px 18px;
}


/* Navbar.css */
.navbar {
    display: flex;
    align-items: center;
    height: auto !important;
    background-color: #fff;
    padding: 0 100px !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-image {
    display: flex;
    align-items: center;
}

.input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
}

.toggle-visibility {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}


.divider {
    margin: 10px 0;
    display: flex;
    align-items: center;
    text-align: center;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
}

.divider:not(:empty)::before {
    margin-right: .25em;
}

.divider:not(:empty)::after {
    margin-left: .25em;
}

.phone-btn {
    width: 100%;
    background-color: #007bff;
    color: white !important;
    padding: 8px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-align: center !important;
}