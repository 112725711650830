/* .containerr-fluid {
    padding: 0px 100px !important;
}

.product-grids {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    margin: 20px 0;
}

.carousel2-heading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: none !important;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    text-align: center;
    transition: transform 0.3s ease;
    margin: 3px;
    height: 200px;
    width: 146px;
}

.product-card:hover {
    box-shadow: 1px 1px 5px 1px #587fb8 !important;
}

.product-image {
    width: 120px;
    height: 140px;
    object-fit: contain;
    border-radius: 8px;
}

.products-info {
    margin-top: 10px;
    width: 100%;
}

.product-name {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
}

.product-name:hover {
    color: #007185;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .product-grids {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }

    .containerr-fluid {
        padding: 0px 20px !important;
    }
}

@media (max-width: 480px) {
    .containerr-fluid {
        padding: 0px 20px !important;
    }

    .product-card {
        margin: 3px !important;
        height: 140px !important;
        padding: 5px 0px !important;
    }

    .product-grids {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
        gap: 10px;
        margin: 10px 0;
    }

    .product-image {
        padding: 0px;
        object-fit: contain;
        border-radius: 8px;
        height: 100px !important;
        width: 80px !important;
    }

    .product-name {
        font-size: 10px;
        font-weight: 600;
        width: 80px !important;
        padding: 0px 8px !important;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
} */


.containerr-fluid {
    padding: 0px 100px;
}

.carousel2-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

/* .carousel2-product-grids {
    display: flex;
    gap: 20px;
} */

.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333;
}

.grid-item.active .products-name {
    color: orange;
    font-weight: bold;
}

.product-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.product-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.products-name {
    font-size: 14px;
    font-weight: 600;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
}

/* Slick Carousel Customization */
/* .containerr-fluid .slick-prev {
    left: 0 !important;
} */

.slick-prev:before,
.slick-next:before {
    color: #333;
}

.slick-dots li button:before {
    color: #ccc;
}

.slick-dots li.slick-active button:before {
    color: #333;
}

@media (max-width: 768px) {
    .containerr-fluid {
        padding: 0px 20px;
    }
}


/* body {
    font-family: "Epilogue", sans-serif !important;
}

.containerr-fluid {
    padding: 0px 20px;
}

.carousel2-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.carousel2-product-grids {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px 20px;
    flex-direction: row;
}

.carousel2-product-grids .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333;
}

.carousel2-product-grids .grid-item.active .product-name {
    color: orange;
    font-weight: bold;
}

.carousel2-product-grids .product-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
    max-width: 100%;
}

.carousel2-product-grids .product-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.carousel2-product-grids .product-name {
    font-size: 14px;
    font-weight: 600;
    transition: color 0.3s;
}

.carousel2-product-grids .grid-item:hover .product-name {
    color: #007185;
}

@media (max-width: 768px) {
    .carousel2-product-grids {
        flex-wrap: nowrap !important;
        gap: 20px;
    }

    .carousel2-product-grids .product-icon {
        width: 30px;
        height: 30px;
    }

    .carousel2-product-grids .product-name {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .carousel2-product-grids {
        flex-direction: row;
        gap: 15px;
    }

    .carousel2-product-grids .product-icon {
        width: 25px;
        height: 25px;
    }

    .carousel2-product-grids .product-name {
        font-size: 10px;
    }
}




.carousel2-product-grids::-webkit-scrollbar {
    height: 5px;
}

.carousel2-product-grids::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.carousel2-product-grids::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}

.carousel2-product-grids::-webkit-scrollbar-track {
    background-color: transparent;
}

@media (max-width: 480px) {
    .carousel2-product-grids {
        gap: 10px;
    }
}

.products-name {
    font-size: 14px;
    font-weight: 600;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
} */