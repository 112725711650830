/* * {
    font-family: Montserrat, sans-serif;
} */

body {
    font-family: "Epilogue", sans-serif !important;
}

.footerr {
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 65px !important;
    /* background-color: #4e4e4e; */
    background-color: #375E97;
    border-top: 1px solid #ccc !important;
    z-index: 1000 !important;
}

.footer-item {
    text-align: center !important;
    flex-grow: 1 !important;
}

.footer-item svg {
    width: 22px;
    height: 22px;
    fill: #fff;
}

.footer-item span {
    display: block !important;
    margin-top: 3px;
    font-size: 14px;
    color: #fff;
}

.footer-item a {
    color: #fff !important;
}

/* Mobile View Styles */
@media (max-width: 768px) {
    .footerr {
        height: 55px !important;
    }

    .footer-item svg {
        width: 22px !important;
        height: 22px !important;
        fill: #fff;
    }

    .footer-item span {
        font-size: 14px;
    }
}