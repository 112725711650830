body {
    font-family: "Epilogue", sans-serif !important;
}

.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding-top: 2%;
    background: #D3D3D3;
}

.signup-card {
    background-color: white;
    border-radius: 20px;
    padding: 10px 30px;
    width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.logo img {
    width: 50px;
    margin-bottom: 15px;
}

.signup-card h2 {
    font-size: 24px;
    margin: 0;
    text-align: left;
}

.signup-card p {
    color: #666;
    margin: 10px 0 20px;
    text-align: left;
}

.form-group {
    position: relative;
    margin-bottom: 15px;
    padding: 0px;
}

.form-group input {
    width: 100%;
    padding: 7px 40px 7px 35px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: black;
}

.input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
}

.toggle-visibility {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.submit-btn {
    width: 100%;
    background-color: #ff7043;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.terms {
    font-size: 12px;
    color: #666;
    text-align: center !important;
}

.terms a {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
}

.terms a:hover {
    text-decoration: underline;
}

.signin-link {
    margin-top: 15px;
    font-size: 14px;
    text-align: center !important;
    font-weight: 600;
}

.signin-link a {
    color: #007bff;
    text-decoration: none;
}

.signin-link a:hover {
    text-decoration: underline;
}

/* Popup Modal Styles */
.otp-popup-overlay {
    position: fixed;
    /* Ensures it covers the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup container */
.otp-popup {
    background: #fff;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
    position: relative;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* OTP input styling */
.otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.otp-input {
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    font-size: 18px;
}

/* Timer styling */
.timer {
    font-size: 14px;
    color: #888;
    margin-top: 10px;
}

/* Resend link styling */
.resend-link {
    color: #007bff;
    cursor: pointer;
}

.resend-link:hover {
    color: #0056b3;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: #888;
    cursor: pointer;
    font-weight: bold;
    line-height: 1;
}

.close-btn:hover {
    color: #333;
}


/* Navbar.css */
.navbar {
    display: flex;
    align-items: center;
    height: auto !important;
    background-color: #fff;
    padding: 0 100px !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-image {
    display: flex;
    align-items: center;
}

.custom-confirm-button {
    background-color: #ff7043 !important;
    color: white;
    border: none !important;
    box-shadow: none !important;
}


/* .logo {
    height: 40px;
    width: auto;
} */