body {
  font-family: "Epilogue", sans-serif !important;
}

.categories-slider {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  padding: 0px 16px;
  padding-top: 12px;
}

.categories-slider::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar in WebKit browsers */
}

.category-link {
  text-decoration: none;
}

.category {
  margin-right: 10px;
  cursor: pointer;
}

.category-image {
  width: 70px;
  /* Adjust according to your preference */
  height: 70px;
  /* Adjust according to your preference */
  border-radius: 50%;
  /* This will make the image appear circular */
  overflow: hidden;
  /* This ensures the image stays within the circular shape */
}

.category-name {
  text-align: center;
  font-size: 12px;
  /* Adjust the font size as needed */
}

.category-carousel-mobile-container {
  text-align: center;
  /* Align the content in the center */
}

.categories-heading {
  font-size: 18px;
  /* Adjust the font size of the heading */
  margin-bottom: 10px;
  /* Add margin below the heading */
}