/* Add this to your existing CSS */
body {
  font-family: "Epilogue", sans-serif !important;
}

.product-info {
  padding: 20px 22px;
}

/* .product-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.product-image {
  flex: 1;
  padding: 1rem;
  text-align: center;
}

.product-description ul {
  padding: 0;
}

.product-image img {
  max-width: 100%;
  max-height: 300px;
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-details {
  flex: 1;
  padding: 1rem;
  text-align: left;
  /* margin-top: -1rem; */
}

.product-header {
  margin-top: 0;
  margin-bottom: 0.5rem;
  /* position: relative; */
}

.product-header .product-variant-group {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.product-header .product-variant-group .product-variant {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.product-header .product-variant-group .product-variant h5 {
  text-align: center;
}

.product-header .product-variant-group .product-variant .product-variant-select-btn {
  padding: 3px 8px;
  border-radius: 30px;
  border: 1px solid #ddd;
  background-color: #ddd;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colors ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.colors {
  width: 100%;
  /* margin-top: 40px; */
}

.colors ul li {
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
  border: 2px solid white;
  margin: 2px 5px 2px 2px;
  border-radius: 50%;
}

.color_image ul {
  padding: 0;
}

.color_image ul li {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #0a0a0a1f;
  margin: 2px;
}

.products-names {
  font-size: 24px;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.product-price {
  font-size: 22px;
  /* margin-bottom: 1rem; */
  font-weight: 500;
  color: #007BFF;
}

.product-price span {
  position: relative;
  top: -7px;
  font-size: 13px;
  padding-right: 3px;
}

.add-to-cart-btn {
  margin-top: 8px;
  background-color: #002246;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 6px 12px;
  /* Adjusted padding */
  font-size: 17px;
  /* Adjusted font size */
  line-height: 30px;
  /* Adjusted line height */
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  min-width: 150px;
  /* Adjusted min-width */
}

.add-to-cart-btn:hover {
  background-color: #0b819b;
  color: #ffffff;
  border-color: #000;
}

.remove-from-wishlist-btn {
  margin-top: 8px;
  background-color: #cd1a1a;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 17px;
  line-height: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  min-width: 150px;
}

.remove-from-wishlist-btn:hover {
  background-color: #da5353;
  color: #ffffff;
  border-color: #000;
}

.proceed-to-checkout-btn {
  margin-top: 8px;
  background-color: #002246;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 17px;
  line-height: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  min-width: 150px;
}

.proceed-to-checkout-btn:hover {
  background-color: #0b819b;
  color: #000;
  border-color: #000;
}

.add-to-wishlist-btn {
  margin-top: 8px;
  background-color: #cd1a1a;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 17px;
  line-height: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  min-width: 150px;
}

.add-to-wishlist-btn:hover {
  background-color: #da5353;
  color: #ffffff;
  border-color: #000;
}

.product-description {
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  margin-top: 1rem;
  padding-top: 1rem;
}

#thumbnails {
  margin-top: 32px;
  margin-left: 70px;
}

#thumbnails img {
  width: 65px;
  height: 65px;
  margin-right: 6px;
  cursor: pointer;
  border-radius: 6px;
}

#thumbnails img:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 480px) {
  #thumbnails img {
    width: 50px;
    height: 50px;
  }
}

#thumbnails img:hover {
  transform: scale(1.05);
}

#main {
  width: 80%;
  height: 400px;
  object-fit: contain;
  display: block;
}

.product-description span {
  color: rgb(105 106 106);
}

@media only screen and (max-width: 480px) {
  #main {
    width: 100%;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .product-info {
    padding: 0px 0px;
  }

  .product-details {
    padding: 6px !important;
  }

  .products-names {
    font-size: 18px;
  }
}

/* .product-info-container {
  margin-top: 20px;
  padding: 0px 80px !important;
} */

/*.product-info {
  display: flex;
}*/

.product-details {
  padding: 20px;
}

.products-names {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-price {
  font-size: 20px;
  /* margin-bottom: 20px; */
  margin-bottom: 0px;
}

.product-description {
  margin-top: 20px;
}

#thumbnails {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

#thumbnails img {
  width: 50px;
  cursor: pointer;
}

.slick-slide {
  padding: 0px 30px;
  margin: 13px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
}

.see-more-btn {
  /* text-decoration: underline #007185 !important; */
  color: #007185 !important;
}

.see-more-btn :hover {
  color: rgb(163, 87, 0);
  text-decoration: underline;
}

.hide-product-details {
  visibility: hidden;
}

.swal2-confirm {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

.swal2-cancel {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}


.shop2 {
  border: 1px solid #00000026;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px;
  /* padding: 10px; */
  background-color: #fff;
  text-align: center;
}


.product-desktop-carousel .shop2 {
  border: 1px solid #00000026;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px;
  padding: 0px 0px;
  background-color: #fff;
  text-align: center;
}

.shop-content {
  padding: 10px 5px 15px;
  text-align: start;
}

.shop-content h3 {
  color: #000000d4;
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 3px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 96%;
  white-space: nowrap;
}

.product-desktop-carousel .shop-content {
  padding: 10px 5px 22px;
  text-align: start;
}

/* Mobile View */
.mobile-carousel .mobile-shop1 {
  border: none;
  box-shadow: none;
  padding: 0;
  background-color: transparent;
  text-align: center;
}

.mobile-carousel .mobile-shop-content h3 {
  color: #000000d4;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 3px;
}

.mobile-carousel img {
  height: 120px;
}

.mobile-carousel .mobile-slide {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mobile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 2 items per row */
  gap: 10px;
  padding: 10px;
}

.mobile-item {
  background-color: white;
  padding: 10px;
  border: 1px solid #00000026;
  border-radius: 10px;
  text-align: center;
}

.mobile-shop-content h3 {
  color: #000000d4;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 3px;
}

/* Hide carousel based on screen size */
@media (max-width: 768px) {
  .product-desktop-carousel {
    display: none;
  }

  .slick-prev {
    left: 0px !important;
  }

  .slick-next {
    right: 0px !important;
  }
}

@media (min-width: 769px) {
  .mobile-carousel {
    display: none;
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }

  .magnify-container img {
    max-height: 520px !important;
    object-fit: contain !important;
    width: auto;
  }

}


.soldd-out-ribbon {
  /* position: absolute; */
  /* top: 60px; */
  left: 0;
  /* background-color: red; */
  color: red;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 10px;
  /* z-index: 5; */
  /* transform: rotate(-45deg); */
  transform-origin: 0 0;
  text-align: center;
  width: 85px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

@media (max-width: 768px) {
  .soldd-out-ribbon {
    font-size: 12px;
    padding: 10px 10px;
    width: 84px;
  }
}


.qty-buttons {
  display: flex;
  align-items: center;
}

.qty-buttons button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #ff7043;
}

.qty-buttons button:hover {
  border-radius: 0;
}

@media only screen and (max-width:767px) {
  .qty-buttons {
    display: contents;
  }

  .qty-buttons button {
    padding: 0px 4px;
  }
}

.image-containers {
  position: relative;
}

.ribbon {
  position: absolute;
  /* top: 10px; */
  /* left: 10px; */
  background-color: red;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  /* border-radius: 4px; */
  z-index: 1;
}

.qty-buttons-container-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 5px; */
}

.qty-button {
  background-color: white;
  color: #ff7043;
  border: none;
  /* border-radius: 4px; */
  width: 25px;
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid;
}

.qty-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]:focus {
  /* border: none !important; */
  outline: 1px solid #ff7043;
  outline-offset: -2px;
}


/* ------------share buttons-------------- */
.share-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 15px;
  margin: 5px 0;
  /* margin: 20px 0; */
}

.share-button {
  background-color: #ffffff;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 3px 8px;
  /* padding: 5px 10px; */
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  text-align: center;
}

.share-button a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  width: 100%;
}

.share-button.facebook {
  background-color: #4267B2;
  border: 1px solid #4267B2;
  color: #fff;
}

.share-button.twitter {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
}

.share-button.whatsapp {
  background-color: #25D366;
  border: 1px solid #25D366;
  color: #fff;
}