body {
    font-family: "Epilogue", sans-serif !important;
}

.myorder-form {
    margin: 20px 0;
}

.myorder-fcont {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px #5fb2f336;
    background: white;
    margin: 18px 0;
}

.myorder-fcont h2 {
    font-weight: 700;
    color: black;
}

.myorder_arrive {
    color: #5a5858;
    font-size: 18px;
}

.myorder_borderb {
    border-bottom: 10px solid #2196f314;
}

.myorder_download {
    font-size: 18px;
    color: green;
    font-weight: 500;
}

.myorder_items {
    padding: 25px 20px
}

.myorder_items h3 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
}

.myorder-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.myorder_primg img {
    width: 70px;
    height: 74px;
    border-radius: 12px;
}


.or-border {
    border-bottom: 1px solid #e3e1e175;
}

.orl-border {
    border-left: 1px solid #e3e1e175;
}

.orr-border {
    border-right: 1px solid #e3e1e175;
}

.myorder-btn {
    color: green;
    font-weight: 600;
    font-size: 18px;
}

.myorder-product {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.moorder-pro {
    width: 66px;
    height: 70px;
    border-radius: 11px;
    border: 1px solid #d5d4d947;
    text-align: center;
    background: #f3f2f8;
}

.moorder-pro img {
    width: 66px;
    height: 70px;
    border-radius: 11px;
}

.myorder-more {
    font-weight: 700;
    font-size: 22px;
    margin: 14px 0;
    display: block;
    color: #a8abb2;
}

.myorderbtn {
    width: 100%;
    padding: 8px;
}

.myorder_sl {
    display: flex;
    justify-content: space-between;
}

.myorder_sp {
    padding: 4px 0;
}

.myorder_hsp {
    color: #5a5858;
    font-size: 15px;
}