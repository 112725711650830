/* .kawal{
    color: red;
}

.special-overlay-inner {
    padding: 10px;
    margin: 0px 10px;
    overflow: hidden;
}
.special-overlay-inner{
  position: relative;
}
.special-overlay:before {
        background: #000;
    opacity: .03;
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 18px;
}
.special-overlay-item{
    overflow: hidden;
}
@media only screen and (min-width: 1200px)
{
  .container, div.container {
    max-width: 1250px;
}
}
.special-overlay-inner img{
-webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding-top: 36px;
    padding-bottom: 6px;
}
.special-overlay-inner:hover img{
-webkit-transform: scale(1.1);
    transform: scale(1.1);
}


.special-overlay-item .special-content {
    position: absolute;
    top: -15px;
    width: 100%;
    left: 0;
    padding: 36px 0px 37px 0px;
    text-align: center;
}
.special-overlay-item .special-content a{
   text-decoration: none;
   color: black;
}
.special-overlay-item .special-content h3{
    font-size: 22px;
    font-weight: 500;
}
.special-overlay-item .special-content p{
      font-weight: 600;
    font-size: 13px;
    color: #a3a3a3
}
.owl-carousel .owl-nav {
    opacity: 0;
    transition: opacity 0.3s ease;
} */

/* Show the Owl Carousel next and previous buttons on hover */
/* .owl-carousel:hover .owl-nav {
    opacity: 1;
} */

/* Additional styling for the next and previous buttons */
/* .owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
    background-color: rgba(0, 0, 0, 0.5); 
    color: white; 
    border: none; 
    border-radius: 50%; 
    padding: 5px; 
} */

/* Position the next and previous buttons */
/* .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    left: 0px; 
    top: 30%; 
    transform: translateY(-50%); 
} */

/* .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    right: 0px; 
    top: 30%;
    transform: translateY(-50%); 
} */



/* .static-grid-container {
    display: flex;
    justify-content: center;
}

.static-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
}

.grid-item {
    overflow: hidden;
}

.special-overlay-inner {
    padding: 10px;
    overflow: hidden;
    position: relative;
}

.special-overlay:before {
    background: #000;
    opacity: .03;
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 18px;
}

.special-overlay-inner img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding-top: 36px;
    padding-bottom: 6px;
}

.special-overlay-inner:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.special-overlay-item .special-content {
    position: absolute;
    top: -15px;
    width: 100%;
    left: 0;
    padding: 36px 0px 37px 0px;
    text-align: center;
}

.special-overlay-item .special-content h3 {
    font-size: 22px;
    font-weight: 500;
} */




body {
    font-family: "Epilogue", sans-serif !important;
}

.static-grid-container {
    display: flex;
    justify-content: center;
    padding: 0px 100px;
}

.static-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    gap: 15px;
}

.grid-item {
    overflow: hidden;
    text-align: center;
}

.special-overlay-inner {
    /* padding: 5px; */
    position: relative;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 50%;
    /* border: 3px solid #afafaf; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.circular-img {
    width: 100%;
    height: 100% !important;
    object-fit: fill;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.special-overlay-inner:hover .circular-img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/* .special-content {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
} */

.special-content h3 {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0 0 0;
}